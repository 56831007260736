import { createStyles, makeStyles } from '@material-ui/core'
import { signIn, useSession } from 'next-auth/client'
import React from 'react'
import Button from './Button/Button'
import Loader from './Loader/Loader'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      justifyContent: 'center',
      display: 'flex',
      height: '100vh',
      alignItems: 'center',
    },
  })
)

const Auth = ({ children }) => {
  const classes = useStyles()
  const [session, loading] = useSession()
  if (process.env.NEXT_AUTH_PASSWORD) {
    if (!session) {
      return (
        <>
          <div className={classes.root}>
            {!loading ? (
              <Button
                variant={'contained'}
                color={'secondary'}
                onClick={() => signIn()}
              >
                Connexion
              </Button>
            ) : (
              <Loader />
            )}
          </div>
        </>
      )
    }
  }

  return children
}

export default Auth
